import { Component, OnInit } from '@angular/core';

interface Project {
  name: string
  type: string,
  url?: string,
  body: string
  note?: string
  linkText?: string,
  technologies: string[],
  previousTechnolgies?: string[]
}

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  projects: Project[]

  constructor() {
    this.projects = [
      { 
        name: 'Node Knockout 2017: Smoothie Operator',
        type: 'node knockout',
        url: 'https://torch-nko-17.herokuapp.com/',
        body: `Party game played by up to 4 people using their smartphones for tilt and motion controls to position their player on a shared screen requiring just a web browser. Work together to make smoothies and keep your company in business.`,        
        technologies: ['NodeJs', 'Express', 'SocketIO', 'React', 'TypeScript', 'Phaser'],
        linkText: 'Play'
      },
      { 
        name: 'Node Knockout 2016: TNTeam',
        type: 'node knockout',        
        body: `Multiplayer platformer where you play as a stick of dynamite trying desperately to save your family while not catching fire and blowing the place up. This project was featured in the PhaserWorld newsletter #56.`,        
        technologies: ['NodeJs', 'SailsJS', 'SocketIO', 'React', 'TypeScript', 'Phaser']
      },
      { 
        name: 'Node Knockout 2012: Death & Donuts',
        type: 'node knockout',      
        body: `Top down deathmatch shooter played in the browser. Join or create a lobby with players from around the world. Find ammo and weapons around the map and use them against the other players.`,        
        technologies: ['NodeJS', 'Canvas', 'Javascript']
      },
      {
        name: 'Alexa Media Center Remote',
        type: 'personal',
        body: 'Web based interface to control over 25 game consoles and streaming devices such as Xbox, Playstation, Android TV, Roku, HDMI splitters and multiple displays. The software runs as a kiosk app on a tablet as well as being controllable by voice with Amazon Alexa. The NodeJS app runs on a Raspberry Pi and interfaces with the Logitech Harmony Hub to control devices over wifi and IR.',
        technologies: ['NodeJS', 'AWS Lambda', 'VueJS', 'Harmony API', 'Roku API']
      },
      {
        name: 'Brave',
        type: 'personal',
        body: 'Browser based multiplayer role playing game where you explore a procedurally generated world to fight monsters and collect treasures. Upgrade your gear and advance to fight stronger monsters alongside your friends from around the world. Throughout the different versions the features have included: Scriptable objects using a Lua interpreter, PVP combat, custom AI for hostile and peaceful NPCs, an economy to buy and sell from vendors as well as other players, questing, crafting, fishing, and farming.',
        technologies: ['NestJs', 'WebSockets', 'MySQL', 'VueJS', 'Canvas'],
        previousTechnolgies: ['SailsJS', 'Express', 'SocketIO', 'Angular', 'React', 'Pixi', 'Phaser', 'SQLite', 'Flash', 'Blessed', 'Telnet', 'Unity']
      }
    ];
   }

  ngOnInit() {
  }

}
