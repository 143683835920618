import { Component, OnInit } from "@angular/core";

interface Role {
  title: string;
  points?: string[];
  company: string;
  start: string;
  end: string;
  body: string;
  // stack: string[];
}

@Component({
  selector: "app-experience",
  templateUrl: "./experience.component.html",
  styleUrls: ["./experience.component.scss"],
})
export class ExperienceComponent implements OnInit {
  history: Role[];

  constructor() {
    this.history = [
      {
        title: "Technical Project Manager",
        company: "Apiture",
        start: "August 2019",
        end: "Present",
        points: [
          "Facilitate Agile Software Development using Scrum",
          "Mentor and train developers on standardized coding practices and secure coding methodology using JavaScript and TypeScript",
          "Architect and Implement Customer Deployments using Amazon Web Services",
          "Clear impediments on the team, company, and vendor level through tight communication",
          "Interviewing and hiring candidates by performing technical screening and scenario based problem solving",
          "Perform product reviews and act as Product Owner for internal application development teams",
          "Establish new employee onboarding procedures from environment setup to access provisioning to ensure rapid integration into the team ",
          "Lead integrations with several 3rd party vendors/APIs such as Yodlee, MX, Deluxe, Ensenta, and Harlande Clarke",
          "Lead internal hackathons with a focus on enhancing security and stability of internal applications",          
        ],
        body: 'As a Technical Project Manager at Apiture I oversee all aspects of Agile Software Development for my assigned teams providing both coaching and hands-on technical guidance. I look for opportunities to suggest new workflows or task sequencing to maximize the team’s productivity and ability to work together. Interviewing and onboarding is a big part of my role as our department continues to grow. My goal is to make my team comfortable working together in breaking down complex problems and feel confident they have everything they need to complete their Sprint commitments.',        
      },
      {
        title: "Sr. Director Application Development",
        company: "Ally",
        start: "June 2017",
        end: "July 2019",
        points: [
          "Lead Department encompassing Software Development, Quality Assurance, and Agile Practices teams comprised of 70+ full-time employees",
          "Bootstrapped and Lead Development of TradeKing Live!, an industry first Award-Winning Web Based Active Trading Platform built using Angular, .NET, NodeJS, WebSockets, and Bootstrap",
          "Built Internal Application Monitoring capabilities and deployed System Health Dashboards using NodeJS, Angular, and Bootstrap to enable real-time monitoring of Ally Invest’s Retail Brokerage Platform",
          "Lead by example, both mentoring and working directly with developers directly contributing to the development of NodeJS and Angular solutions",
          "Encouraged pair programming, whiteboarding, and all forms of collaboration",
          "Architected and Implemented Deployment Automation for multiple Web Applications across both an on-premises datacenter and Amazon Web Services.",
          "Facilitated Ally Invest JavaScript Community of Practice, which allowed developers to collaborate around JavaScript frameworks, tooling, testing, monitoring, and application debugging",
          "Administered internal development tools including Slack, GitHub, TeamCity, and Octopus",
          "Manage 3rd party vendor relationships for our development stack and cloud based services such as Markit On Demand, Yodlee, Sungard, and APEX Clearing.",
          "Liaison between development teams and executive management",
          "Provided production support for both customer facing and internal applications",
        ],
        body: 'In my role reporting to the CIO of Ally Bank, I lead the Brokerage Development Organization at Ally Invest to deliver and support our award-winning, self-directed retail trading platforms. I managed many of our third party relationships and platforms providing data and services to the Brokerage including Clearing, Market Data, Execution, and Data Aggregation.  I worked closely with our Engineering and Operations team to deliver and support servers and tools to our application teams.',
        // stack: [
        //   "AngularJS",
        //   "Angular",
        //   "NodeJS",
        //   "Express",
        //   "Sails",
        //   "nginx",
        //   ".NET C#",
        //   "MSSQL",
        //   "RabbitMQ",
        //   "Redis",
        // ],
      },                
      {
        title: "Sr. Software Developer",
        company: "Atypic Craft",
        start: "May 2009",
        end: "2011",
        points: [],
        body: '',
        // stack: ["PHP", "MySQL", "jQuery", "lodash", "AutoIt"],
      },
      {
        title: "Software Developer",
        company: "Paira Creative",
        start: "April 2007",
        end: "2009",
        points: [],
        body: '',
        // stack: ["PHP", "jQuery", "lodash"],
      },
      {
        title: "Flash Developer",
        company: "Steel Roots",
        start: "March 2005",
        end: "2007",
        points: [],
        body: '',
        // stack: ["Flash", "AS2", "AS3", "PHP", "XML", "Photoshop"],
      },
    ];
  }

  ngOnInit() {}
}
