import { Component, OnInit } from '@angular/core';
declare global {
  interface Window { jQuery: any; }
}
const jQuery:any = (window.jQuery as any);
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'portfolio';
  
  ngOnInit() {
    jQuery('a.js-scroll-trigger[href*="#"]:not([href="#"])').bind('click', function() {
      if (location.pathname.replace(/^\//, "") == this.pathname.replace(/^\//, "") && location.hostname == this.hostname) {
      var e = jQuery(this.hash);
      if ((e = e.length ? e : jQuery("[name=" + this.hash.slice(1) + "]")).length) return jQuery("html, body").animate({ scrollTop: e.offset().top }, 1e3, "easeInOutExpo"), !1
    }
    }), jQuery(".js-scroll-trigger").click(function() {
      jQuery(".navbar-collapse").collapse("hide")
    }), jQuery("body").scrollspy({
      target: "#sideNav"
    })
  }
}
